export function createCheckout(shop, formData, cart, checkout) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_CDN_URL}order/create?shop=${shop}&type=market_enabled`, {
            method: "POST",
            body: JSON.stringify({
                "cart": cart,
                "formData": formData,
                "checkout": checkout
            })
        })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            if (data.errors) {
                reject(data);
            }
            resolve(data);
        })
        .catch(function(err) {
            console.log(err);
            reject(err);
        });
    });
}

export function shippingRates(checkout, shop) {
    return new Promise(function(resolve, reject) {
        fetch(`${process.env.REACT_APP_CDN_URL}order/rates`, {
            method: "POST",
            body: JSON.stringify({
                "shop": shop,
                "checkout": checkout,
                "type": "market_enabled"
            })
        })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            resolve(data);
        })
        .catch(function(err) {
            reject(err.error);
        });
    });
}

export function applyDiscount(checkout, shop, code) {
    return new Promise(function(resolve, reject) {
        fetch(`${process.env.REACT_APP_CDN_URL}order/discount`, {
            method: "POST",
            body: JSON.stringify({
                "shop": shop,
                "code": code,
                "checkout": checkout,
                "type": "market_enabled"
            })
        })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            resolve(data);
        })
        .catch(function(err) {
            console.log(err);
            reject(err.error);
        });
    });
}

export function setShipping(checkout, shop, handle) {
    return new Promise(function(resolve, reject) {
        fetch(`${process.env.REACT_APP_CDN_URL}order/shipping`, {
            method: "POST",
            body: JSON.stringify({
                "shop": shop,
                "checkout": checkout,
                "shipping": handle,
                "type": "market_enabled"
            })
        })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            resolve(data);
        })
        .catch(function(err) {
            reject(err.error);
        });
    });
}

export function checkoutDetails(handle, store) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL + `checkout/valid?handle=${handle}&store=${store}`)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export const updateMetaTags = (tags) => {
    return {
        type: "SET_TAGS",
        payload: {
            tags
        }
    }
}

export const resetMetaTags = () => {
    return {
        type: "RESET_TAGS"
    }
}

export function submitPayment(token, shop, checkout, billing, userId, variantId, productId, email, accid) {
    return new Promise(function(resolve, reject) {
        fetch(process.env.REACT_APP_CDN_URL + "order/payment", {
            method: "POST",
            body: JSON.stringify({
                "shop": shop,
                "cardToken": token,
                "checkoutToken": checkout.checkout.token,
                "billing": billing,
                "type": "market_enabled",
                "userId": userId,
                "variant": variantId,
                "product": productId,
                "email": email,
                "accid": accid
            })
        })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            resolve(data);
        })
        .catch(function(err) {
            reject(err);
        });
    });
}

export function paymentIntent(shop, accid, order, userId) {
    return new Promise(function(resolve, reject) {
        fetch(process.env.REACT_APP_CDN_URL + `order/intent`, {
            method: "POST",
            body: JSON.stringify({
                "shop": shop,
                "type": "market_enabled",
                "accid": accid,
                "order": order,
                "userId": userId
            })
        })
        .then(function(res) {
            return res.json();
        })
        .then(function(data) {
            resolve(data);
        })
        .catch(function(err) {
            reject(err);
        });
    });
}