import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import ClipLoader from "react-spinners/ClipLoader";
import DocumentMeta from 'react-document-meta';

import { 
    createCheckout, shippingRates, applyDiscount, 
    setShipping, checkoutDetails, updateMetaTags,
    submitPayment, paymentIntent
} from './actions/';

import Loading from './loading';
import Error from './error';

import { currencies } from './currencies';
import { countries } from './countries';

let stripe;

class Checkout extends ReactQueryParams {

    state = {
        error: false,
        loading: true,
        data: {images: [], variants: []},
        variantId: '',
        subTotal: 0.00,
        total: 0.00,
        shipping: 0.00,
        discount: 0.00,
        email: '',
        userId: this.queryParams.ref || '',
        symbol: '',
        savingCheckout: false,
        savingShipping: false,
        order: null,
        store: '',
        shopId: '',
        handle: '',
        quantity: 1,
        sameAsShipping: true,
        applyingDiscount: false,
        code: '',
        checkoutLoading: true,
        loadingText: 'Calculating shipping...',
        discountError: '',
        rates: [],
        rateId: '',
        accid: '',
        payError: '',
        card: null,
        delivery: { 
            'ship-first-name': '',
            'ship-last-name': '',
            'ship-address': '',
            'ship-city': '',
            'ship-state': '',
            'ship-zip': '',
            'ship-country':''
        },
        billing: { 
            'billing-first-name': '',
            'billing-last-name': '',
            'billing-address': '',
            'billing-city': '',
            'billing-state': '',
            'billing-zip': '',
            'billing-country':''
        }
    }

    componentDidMount = async () => {
        let { store, handle } = this.props.match.params;
        if (!store || !handle) {
            this.setState({error: true});
        } else {     
            let data = await checkoutDetails(handle, store);
            if (!data.error) {
                if (!data.variants || data.variants.length === 0) {
                    this.setState({error: true});
                } else {
                    let variantId = (data.variants.length === 1) ? data.variants[0] : '';
                    let currencySymbol = currencies[data.currency].symbol;
                    this.setState({
                        loading: false,
                        data: data,
                        symbol: currencySymbol,
                        variantId,
                        store: store,
                        shopId: data.shop,
                        handle: handle
                    }, () => {
                        this.props.updateMetaTags({
                            title: 'Checkout - ' + data.title,
                            description: "BoxUp Checkouts for dropshippers.",
                            meta: {
                                charset: 'utf-8',
                                name: {
                                    keywords: 'checkout, dropship, boxup, Shopify'
                                }
                            }
                        });
                        var owl = window.$('.owl-carousel');
                            owl.owlCarousel({
                            margin: 10,
                            loop: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                                980: {
                                    items: 2
                                }
                            }
                        });
                    });
                }
            } else {
                this.setState({error: true});
            }
        }
    }

    setVariant = (variantId) => {
        if (variantId !== "" && variantId) {
            let shipping = Number(this.state.shipping);
            let selected = this.state.data.variants.filter(item => item.variantId.toString() === variantId.toString());
            let price = Number(selected[0].price);
            this.setState({
                variantId,
                subTotal: price,
                total: Number(price) + Number(shipping)
            });
        } else {
            this.setState({
                variantId: '',
                subTotal: 0.00,
                total: 0.00
            });
        }
    }

    setShipping = (e) => {
        e.preventDefault();
        this.id("ship-error").innerHTML = "";
        this.id("variantId").style.border = "";
        var elements = this.id("bxa-delivery-form").elements;
        let error = false;
        for (var i = 0, element; element = elements[i++];) {
            if (element.required && element.value === "") {
                element.style.border = "1px solid red";
                error = true;
            } else {
                element.style.border = "";
            }
        }
        if (!error) {
            let variantId = this.state.variantId;
            if (variantId === '' || !variantId) {
                this.id("ship-error").innerHTML = "Please select an option.";
                this.id("variantId").style.border = "1px solid red";
            } else {
                // set billing address
                this.copyAddress(true);
                this.setState({savingShipping: true}, async() => {
                    let cart = {
                        items: [{
                            variant_id: this.state.variantId,
                            quantity: this.state.quantity
                        }]
                    };
                    let formData = this.getFormData(this.id("bxa-delivery-form"));
                    let newOrder = await createCheckout(this.state.store, formData, cart, this.state.order);
                    this.changeTab("bxa-payment");
                    let data = await shippingRates(newOrder, this.state.store);

                    let rates = data.shipping_rates;
                    if (!rates || rates.length < 1) {
                        this.setError("We're unable to deliver to the shipping address provided.");
                    } else {
                        let rateOpts = [];
                        rates.map((rate, r) => {
                            rateOpts.push(<option 
                                key={r} 
                                price={rate.price}
                                value={rate.handle}>{rate.title} - {this.state.symbol}{rate.price}
                            </option>);
                        });
                        let shippingData = await setShipping(newOrder, this.state.store, rates[0].handle);

                        let accid = shippingData.checkout.shopify_payments_account_id;
                        stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK, {
                            stripeAccount: accid,
                        });
                        var elements = stripe.elements({
                            fonts: [{
                                family: 'Poppins',
                                src: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap',
                                weight: '400',
                            }]
                        });
                        // Create an instance of the card Element.
                        var style = {
                            base: {
                                color: '#777777',
                                height: 'calc(2.6rem + 2px)',
                                padding: '.610rem .96rem',
                                fontFamily: '"Poppins", sans-serif',
                                fontSize: '16px',
                                '::placeholder': {
                                color: '#b1b4b6'
                                }
                            },
                            invalid: {
                                color: '#fa755a',
                                iconColor: '#fa755a'
                            }
                        };
                        var card = elements.create('card', {style: style});
                        card.mount('#card-element');
                        card.addEventListener('change', (event) => {
                            if (event.error) {
                                this.setState({payError: event.error.message});
                            } else {
                                this.setState({payError: ''});
                            }
                        });

                        if (accid) {
                            this.setState({
                                savingShipping: false,
                                rateOpts: rateOpts,
                                checkoutLoading: false,
                                order: shippingData,
                                rates: rates,
                                rateId: rates[0].handle,
                                shipping: Number(rates[0].price) || 0.00,
                                subTotal: Number(shippingData.checkout.subtotal_price),
                                total: Number(shippingData.checkout.total_price),
                                accid: accid,
                                card: card
                            });
                        } else {
                            this.setError("This product is not available at the moment.");
                        }
                    }
                });
            }
        }
    }

    fillBilling = () => {
        let shipping = this.state.delivery;
        let billing = {
            'billing-first-name': shipping['ship-first-name'],
            'billing-last-name': shipping['ship-last-name'],
            'billing-address': shipping['ship-address'],
            'billing-city': shipping['ship-city'],
            'billing-state': shipping['ship-state'],
            'billing-zip': shipping['ship-zip'],
            'billing-country': shipping['ship-country']
        }
        this.setState({billing});
    }

    getFormData = (form) => {
        let obj = {};
        var elements = form.querySelectorAll("input, select, textarea");
        for (var i = 0; i < elements.length; ++i) {
            var element = elements[i];
            var name = element.name;
            var value = element.value;
            if (name) {
                obj[name] = value;
            }
        }
        return obj;
    }

    id(i) {
        return window.document.getElementById(i);
    }

    changeTab(to) {
        let from = (to === 'bxa-payment') ? 'bxa-delivery' : 'bxa-payment';
        this.id(to + "-tab").classList.add("bxa-active");
        this.id(from + "-tab").classList.remove("bxa-active");
        this.id("bxa-error-tab").classList.remove("bxa-active");
        this.id("bxa-error").classList.remove("bxa-show","bxa-active");
        this.id(from).classList.remove("bxa-show","bxa-active");
        this.id(to).classList.add("bxa-active","bxa-show");
    }

    copyAddress = (chckd) => {
        this.setState({sameAsShipping: chckd});
        if (chckd) {
            this.fillBilling();
        }
    }

    setError(msg) {
        this.setState({order: null});
        this.id("bxa-error-msg").textContent = msg;
        let from = 'bxa-payment', to = 'bxa-error';
        this.id(to + "-tab").classList.add("bxa-active");
        this.id(from + "-tab").classList.remove("bxa-active");
        this.id(from).classList.remove("bxa-show","bxa-active");
        this.id(to).classList.add("bxa-active","bxa-show");
        this.id("bxa-lottie-error").innerHTML = '';
        window.lottie.loadAnimation({
            container: window.document.getElementById('bxa-lottie-error'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: 'https://cdn.boxup.io/images/error'
        });
    }

    setDiscount = async() => {
        let { code, order, store } = this.state;
        this.setState({
            checkoutLoading: true,
            loadingText: 'Checking code...',
            discountError: ''
        }, async() => {
            
            if (!code) {
                this.id("bxa-promo").style.border = "1px solid red";
            } else {
                let data = await applyDiscount(order, store, code);
                if (data.errors) {
                    this.setState({
                        discountError: "Invalid discount code.",
                        checkoutLoading: false
                    });
                } else {
                    let discount = data.checkout.applied_discount;                    
                    this.setState({
                        checkoutLoading: false,
                        discountError: '',
                        order: data,
                        subTotal: Number(data.checkout.subtotal_price),
                        total: Number(data.checkout.total_price),
                        discount: Number(discount.amount)
                    });
                }
            }
        });
    }

    setShippingValue = async(shippingHandle) => {
        let selected = this.state.rates.filter(rate => rate.handle === shippingHandle);
        if (selected.length > 0) {
            this.setState({
                checkoutLoading: true,
                loadingText: 'Applying shipping...'
            }, async() => {
                let { store, order } = this.state;
                let data = await setShipping(order, store, shippingHandle);
                this.setState({
                    checkoutLoading: false,
                    rateId: shippingHandle,
                    shipping: Number(selected[0].price),
                    order: data,
                    subTotal: Number(data.checkout.subtotal_price),
                    total: Number(data.checkout.total_price)
                });
            });
        } else {
            this.setError("No shipping options are available to that location.");
        }
    }

    updateShipping = (key, val) => {
        let delivery = this.state.delivery;
        delivery[key] = val;
        this.setState({delivery});
    }

    updateBilling = (key, val) => {
        let billing = this.state.billing;
        billing[key] = val;
        this.setState({billing});
    }

    makePayment = () => {
        let { email, card, store, order, billing, userId, variantId } = this.state;
        let product = this.state.data.productId;
        if (!email || !email.includes("@")) {
            this.setState({payError: 'Please enter a valid email.'})
        } else {
            this.setState({
                checkoutLoading: true,
                loadingText: 'Processing payment...'
            }, async() => {
                let errors = [];
                Object.keys(billing).map(key => {
                    if (!billing[key]) {
                        errors.push(key);
                    }
                });
                if (errors.length === 0) {
                    
                    stripe.createToken(card).then(async(result) => {
                        console.log("card confirm", result);
                        if (result.error) {
                            this.setState({
                                checkoutLoading: false,
                                payError: result.error.message
                            });
                        } else {
                            // Show a success message to your customer
                            // There's a risk of the customer closing the window before callback
                            // execution. Set up a webhook or plugin to listen for the
                            // payment_intent.succeeded event that handles any business critical
                            // post-payment actions.
                            
                            let data = await submitPayment(
                                result.token, 
                                store, 
                                order, 
                                billing, 
                                userId, 
                                variantId,
                                product,
                                email,
                                this.state.accid
                            );
                            console.log("data", data)
                            this.setState({
                                checkoutLoading: false
                            });
                            if (data.errors) {
                                this.setError(data.errors.base[0].message);
                            } else if (data.error) {
                                this.setError(data.message);
                            } else {
                                this.completeCheckout();
                            }
                        }
                    });
                } else {
                    this.setState({
                        checkoutLoading: false,
                        payError: 'Please fill out your billing address.'
                    });
                }
            });
        }
    }

    completeCheckout = () => {
        let from = 'bxa-payment', to = 'bxa-complete';
        this.id(to + "-tab").classList.add("bxa-active");
        this.id(from + "-tab").classList.remove("bxa-active");
        this.id(from).classList.remove("bxa-show","bxa-active");
        this.id(to).classList.add("bxa-active","bxa-show");
        window.lottie.loadAnimation({
            container: this.id('bxa-lottie'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: 'https://cdn.boxup.io/images/paid'
        });
        this.id("bxa-delivery-tab").onclick = null;
        this.id("bxa-payment-tab").onclick = null;
        this.id("bxa-tabs-list").style.display = "none";
    }
    
    render() {
        let { 
            data, subTotal, symbol, total, savingShipping, 
            shipping, rateOpts, discount, order, sameAsShipping,
            checkoutLoading, payError
        } = this.state;
        let countryList = [];
        Object.keys(countries).forEach(key => {
            countryList.push(
                <option
                    key={key}
                    value={key}
                >{countries[key]}
                </option>
            );
        });
        return (
            <DocumentMeta {...this.props.meta.tags}>
                {this.state.error &&
                <Error/>
                }
                {!this.state.error && !this.state.loading && 
                <div className="bxa-modal-box bxa-container">
                    <div className="bxa-modal-box__content"> 
                        <div className="bxa-bg-light bxa-shadow-md bxa-rounded bxa-p-4">
                            <div className="bxa-row">             
                                    <div className="bxa-col-md-6 bxa-col-lg-6 bxa-order-0 bxa-order-md-1"> 
                                        <h3 className="bxa-text-6 bxa-mb-4 bxa-headers bxa-title">Checkout for {data.title}</h3>
                                        <div className="bxa-bg-light-2 bxa-rounded bxa-p-4 bxa-mb-4">
                                            <div className="owl-carousel owl-theme">
                                                {data.images.map((image, x) => {
                                                    return <div key={x} className="item">
                                                        <img src={image.src} className="bxa-image"/>
                                                    </div>
                                                })}
                                            </div>
                                            <div className="bxa-form-group">
                                                <div id="ship-error" className="ship-error"></div>
                                            </div>
                                            <div className="bxa-form-group">
                                                {data.variants.length > 1 && 
                                                <>
                                                <label htmlFor="variantId">Select option</label>
                                                <select 
                                                    id="variantId" 
                                                    name="variantId" 
                                                    className="bxa-custom-select" 
                                                    required
                                                    value={this.state.variantId}
                                                    onChange={(e) => this.setVariant(e.target.value)}
                                                >
                                                    <option value="">Please select</option>
                                                    {data.variants.map((v, i) => {
                                                        return <option key={i} value={v.variantId}>
                                                            {v.title} - {v.formattedPrice}
                                                        </option>
                                                    })}
                                                </select>
                                                </>
                                                }
                                                {data.variants.length === 1 && 
                                                <input 
                                                    type="hidden"
                                                    id="variantId" 
                                                    name="variantId" 
                                                    value={data.variants[0].variantId}
                                                    className="bxa-custom-select" 
                                                    required
                                                />
                                                }
                                            </div>
                                            <ul className="bxa-list-unstyled" id="bxa-discounts">
                                                {discount > 0.00 &&
                                                <li className="bxa-mb-2">Discount {` `}
                                                    <span className="bxa-text-success">{order.checkout.discount_code}</span> 
                                                    <span className="bxa-float-right bxa-text-4 bxa-font-weight-500 bxa-text-dark">-{symbol}{(discount).toFixed(2)} </span>
                                                </li>
                                                }
                                                <hr/>
                                            </ul>
                                            <div className="bxa-text-dark bxa-text-4 bxa-font-weight-500 bxa-py-1"> Subtotal<span className="bxa-float-right bxa-text-6"><span id="bxa-currency">{symbol}</span><span id="bxa-sub-total">{(subTotal).toFixed(2)}</span></span></div>
                                            <div className="bxa-text-dark bxa-text-4 bxa-font-weight-500 bxa-py-1"> Shipping<span className="bxa-float-right bxa-text-6"><span>{symbol}</span><span>{(shipping).toFixed(2)}</span></span></div>
                                            <div className="bxa-text-dark bxa-text-5 bxa-font-weight-500 bxa-py-1"> Total<span className="bxa-float-right bxa-text-7">{symbol}<span id="bxa-total">{(total).toFixed(2)}</span></span></div>
                                        </div>
                                        
                                        <div className="bxa-bg-light-2 bxa-rounded bxa-p-4 bxa-d-none bxa-d-md-block">
                                            <p className="bxa-mb-2">We value your privacy.</p>
                                            <p className="bxa-text-1 bxa-mb-0">
                                                You can read our <a target="_blank" href="https://dropship.boxup.io/privacy">Privacy Policy</a> and <a target="_blank" href="https://dropship.boxup.io/terms">Terms and Conditions</a>.</p>
                                            <hr/>
                                            <p className="bxa-mb-2">Billing Enquiries</p>
                                            <p className="bxa-text-1 bxa-mb-0">To contact us, please get in touch by <a href="mailto:support@boxup.io">email</a>.</p>
                                            <div className="bxa-payment-methods">
                                                <img src="https://cdn.boxup.io/images/visa.svg"/>
                                                <img src="https://cdn.boxup.io/images/mastercard.svg"/>
                                                <img src="https://cdn.boxup.io/images/amex.svg"/>
                                                <img src="https://cdn.boxup.io/images/apple.svg"/>
                                                <img src="https://cdn.boxup.io/images/google.svg"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bxa-col-md-6 bxa-col-lg-6 bxa-order-1 bxa-order-md-0" id="bxa-tabs">
                                        <ul className="bxa-nav bxa-nav-tabs bxa-mb-4" id="bxa-tabs-list" role="tablist">
                                            <li className="bxa-nav-item"> 
                                                <a className="bxa-nav-link bxa-active" id="bxa-delivery-tab" data-toggle="tab" href="#this" role="tab" aria-controls="firstTab" aria-selected="true">Shipping</a> 
                                            </li>
                                            <li className="bxa-nav-item"> 
                                                <a className="bxa-nav-link" id="bxa-payment-tab" data-toggle="tab" href="#this" role="tab" aria-controls="secondTab" aria-selected="false">Payment</a> 
                                            </li>
                                            <li className="bxa-nav-item"> 
                                                <a className="bxa-nav-link" id="bxa-complete-tab" data-toggle="tab" href="#this" role="tab" aria-controls="thirdTab" aria-selected="false">Summary</a> 
                                            </li>
                                            <li className="bxa-nav-item bxa-error-tab"> 
                                                <a className="bxa-nav-link" id="bxa-error-tab" data-toggle="tab" href="#this" role="tab" aria-controls="fourthTab" aria-selected="false">Error</a> 
                                            </li>
                                        </ul>
                                        <div className="bxa-tab-content bxa-my-3"> 
                                            <div className="bxa-tab-pane bxa-fade bxa-show bxa-active" id="bxa-delivery" role="tabpanel" aria-labelledby="first-tab">
                                                <div className="bxa-row">
                                                    <div className="bxa-col-lg-12">
                                                        <h3 className="bxa-text-4 bxa-mb-4 bxa-headers">Shipping Address Details</h3>
                                                        <form id="bxa-delivery-form" onSubmit={this.setShipping}>
                                                            <div className="bxa-form-group bxa-form-row">
                                                                <div className="bxa-col-lg-6">
                                                                    <label htmlFor="bxa-delivery-name">First name</label>
                                                                    <input 
                                                                        type="text" 
                                                                        id="ship-first-name" 
                                                                        className="bxa-form-control" 
                                                                        name="ship-first-name" 
                                                                        required
                                                                        placeholder="e.g. John" 
                                                                        autoComplete="given-name"
                                                                        value={this.state.delivery['ship-first-name']}
                                                                        onChange={e => this.updateShipping('ship-first-name', e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="bxa-col-lg-6">
                                                                    <label htmlFor="bxa-delivery-name">Last name</label>
                                                                    <input 
                                                                        type="text" 
                                                                        id="ship-last-name" 
                                                                        className="bxa-form-control" 
                                                                        name="ship-last-name" 
                                                                        required
                                                                        placeholder="e.g. Smith" 
                                                                        autoComplete="family-name"
                                                                        value={this.state.delivery['ship-last-name']}
                                                                        onChange={e => this.updateShipping('ship-last-name', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="bxa-form-group">
                                                                <label htmlFor="ship-address">Address</label>
                                                                <input 
                                                                    type="text" 
                                                                    id="ship-address" 
                                                                    className="bxa-form-control" 
                                                                    name="ship-address" 
                                                                    required 
                                                                    placeholder="e.g. 123 Main Street" 
                                                                    autoComplete="shipping street-address"
                                                                    value={this.state.delivery['ship-address']}
                                                                    onChange={e => this.updateShipping('ship-address', e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="bxa-form-row">
                                                                <div className="bxa-col-lg-6">
                                                                    <div className="bxa-form-group">
                                                                        <label htmlFor="bxa-delivery-name">City</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="ship-city" 
                                                                            className="bxa-form-control" 
                                                                            name="ship-city" 
                                                                            required 
                                                                            autoComplete="shipping locality"
                                                                            value={this.state.delivery['ship-city']}
                                                                            onChange={e => this.updateShipping('ship-city', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="bxa-col-lg-6">
                                                                    <div className="bxa-form-group">
                                                                        <label htmlFor="bxa-delivery-name">State</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="ship-state" 
                                                                            className="bxa-form-control" 
                                                                            autoComplete="shipping region" 
                                                                            name="ship-state" 
                                                                            placeholder="State/County"
                                                                            value={this.state.delivery['ship-state']}
                                                                            onChange={e => this.updateShipping('ship-state', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bxa-form-row">
                                                                <div className="bxa-col-lg-6">
                                                                    <div className="bxa-form-group">
                                                                        <label htmlFor="bxa-delivery-name">Zip</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="ship-zip" 
                                                                            autoComplete="shipping postal-code" 
                                                                            className="bxa-form-control" 
                                                                            name="ship-zip" 
                                                                            required 
                                                                            placeholder="Zip/Postal Code"
                                                                            value={this.state.delivery['ship-zip']}
                                                                            onChange={e => this.updateShipping('ship-zip', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="bxa-col-lg-6">
                                                                    <div className="bxa-form-group">
                                                                        <label htmlFor="bxa-delivery-name">Country</label>
                                                                        <select 
                                                                            id="ship-country" 
                                                                            name="ship-country" 
                                                                            autoComplete="shipping country" 
                                                                            className="bxa-custom-select" 
                                                                            required
                                                                            value={this.state.delivery['ship-country']}
                                                                            onChange={e => this.updateShipping('ship-country', e.target.value)}
                                                                        >
                                                                            <option value="">Please select</option>
                                                                            {countryList}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!savingShipping &&
                                                            <button className="bxa-btn bxa-btn-primary bxa-btn-block" type="submit">Proceed to Payment</button>
                                                            }
                                                            {savingShipping &&
                                                            <button className="bxa-btn bxa-btn-primary bxa-btn-block" type="button" disabled>
                                                                <ClipLoader
                                                                    size={15}
                                                                    color={"#ffffff"}
                                                                    loading={savingShipping}
                                                                />
                                                            </button>
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bxa-tab-pane bxa-fade" id="bxa-payment" role="tabpanel" aria-labelledby="second-tab"> 
                                                <div className="bxa-row">
                                                    <div className="bxa-col-lg-12">
                                                        <h3 className="bxa-text-4 bxa-mb-4 bxa-headers">Your Payment Details</h3>
                                                        {this.state.checkoutLoading && 
                                                        <div id="payment-load">
                                                            <img src="https://cdn.boxup.io/images/dark-loading.svg"/>
                                                            <p id="bxa-load-text" className="bxa-load-text">{this.state.loadingText}</p>
                                                        </div>
                                                        }
                                                        <form id="bxa-payment-form" style={{display: (checkoutLoading) ? 'none' : 'block'}}>
                                                            <div id="bxa-shipping-row">
                                                                <div className="bxa-form-group">
                                                                    <label htmlFor="bxa-shipping">Shipping Options</label>
                                                                    <select 
                                                                        id="bxa-shipping" 
                                                                        name="bxa-shipping" 
                                                                        className="bxa-custom-select"
                                                                        value={this.state.rateId}
                                                                        onChange={(e) => this.setShippingValue(e.target.value)}
                                                                    >{rateOpts}</select>
                                                                </div>
                                                            </div>
                                                            <div className="bxa-form-row" id="bxa-discount">
                                                                <div className="bxa-col-xs-8">
                                                                    <div className="bxa-form-group">
                                                                        <label htmlFor="bxa-promo">Promotion Code</label>
                                                                        <input 
                                                                            type="text" 
                                                                            className="bxa-form-control" 
                                                                            id="bxa-promo" 
                                                                            name="bxa-promo" 
                                                                            placeholder="Enter your code"
                                                                            value={this.state.code}
                                                                            onChange={(e) => this.setState({code: e.target.value})}
                                                                        />
                                                                        <span id="bxa-promo-error" className="bxa-promo-error">
                                                                            {this.state.discountError}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="bxa-col-xs-4">
                                                                    <button 
                                                                        className="bxa-btn bxa-btn-dark" 
                                                                        id="bxa-discount-btn" 
                                                                        type="button"
                                                                        onClick={this.setDiscount}
                                                                    >Apply</button>
                                                                </div>
                                                            </div>
                                                            <div className="bxa-form-group">                                            
                                                                <input 
                                                                    id="bxa-copy-address" 
                                                                    type="checkbox" 
                                                                    checked={this.state.sameAsShipping}
                                                                    onChange={e => this.copyAddress(e.target.checked)}
                                                                />
                                                                <label htmlFor="bxa-copy-address" className="bxa-checkbox-label">
                                                                    Billing address is the same as shipping
                                                                </label>
                                                            </div>
                                                            <div id="bxa-billing" style={{display: (sameAsShipping) ? 'none' : 'block'}}>
                                                                <hr/>
                                                                <div className="bxa-form-group bxa-form-row">
                                                                    <div className="bxa-col-lg-6">
                                                                        <label htmlFor="bxa-delivery-name">First name</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="billing-first-name" 
                                                                            className="bxa-form-control" 
                                                                            name="billing-first-name" 
                                                                            required 
                                                                            placeholder="e.g. John" 
                                                                            autoComplete="given-name"
                                                                            value={this.state.billing['billing-first-name']}
                                                                            onChange={e => this.updateBilling('billing-first-name', e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="bxa-col-lg-6">
                                                                        <label htmlFor="bxa-delivery-name">Last name</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="billing-last-name" 
                                                                            className="bxa-form-control" 
                                                                            name="billing-last-name" 
                                                                            required 
                                                                            placeholder="e.g. Smith" 
                                                                            autoComplete="family-name"
                                                                            value={this.state.billing['billing-last-name']}
                                                                            onChange={e => this.updateBilling('billing-last-name', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="bxa-form-group">
                                                                    <label htmlFor="ship-address">Address</label>
                                                                    <input  
                                                                        type="text" 
                                                                        id="billing-address" 
                                                                        className="bxa-form-control" 
                                                                        name="billing-address" 
                                                                        required 
                                                                        placeholder="e.g. 123 Your Street" 
                                                                        autoComplete="shipping street-address"
                                                                        value={this.state.billing['billing-address']}
                                                                        onChange={e => this.updateBilling('billing-address', e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="bxa-form-row">
                                                                    <div className="bxa-col-lg-6">
                                                                        <div className="bxa-form-group">
                                                                            <label htmlFor="bxa-delivery-name">City</label>
                                                                            <input 
                                                                                type="text" 
                                                                                id="billing-city" 
                                                                                className="bxa-form-control" 
                                                                                name="billing-city" 
                                                                                required 
                                                                                autoComplete="shipping locality"
                                                                                value={this.state.billing['billing-city']}
                                                                                onChange={e => this.updateBilling('billing-city', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="bxa-col-lg-6">
                                                                        <div className="bxa-form-group">
                                                                            <label htmlFor="bxa-delivery-name">State</label>
                                                                            <input 
                                                                                type="text" 
                                                                                id="billing-state" 
                                                                                className="bxa-form-control" 
                                                                                autoComplete="shipping region" 
                                                                                name="billing-state" 
                                                                                placeholder="State/County"
                                                                                value={this.state.billing['billing-state']}
                                                                                onChange={e => this.updateBilling('billing-state', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bxa-form-row">
                                                                    <div className="bxa-col-lg-6">
                                                                        <div className="bxa-form-group">
                                                                            <label htmlFor="bxa-delivery-name">Zip</label>
                                                                            <input 
                                                                                type="text" 
                                                                                id="billing-zip" 
                                                                                autoComplete="shipping postal-code" 
                                                                                className="bxa-form-control" 
                                                                                name="billing-zip" 
                                                                                required 
                                                                                placeholder="Zip/Postal Code"
                                                                                value={this.state.billing['billing-zip']}
                                                                                onChange={e => this.updateBilling('billing-zip', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="bxa-col-lg-6">
                                                                        <div className="bxa-form-group">
                                                                            <label htmlFor="bxa-delivery-name">Country</label>
                                                                                <select 
                                                                                    id="billing-country" 
                                                                                    name="billing-country" 
                                                                                    autoComplete="shipping country" 
                                                                                    className="bxa-custom-select" 
                                                                                    required
                                                                                    value={this.state.billing['billing-country']}
                                                                                    onChange={e => this.updateBilling('billing-country', e.target.value)}
                                                                                >
                                                                                <option value="">Please select</option>
                                                                                {countryList}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr/>
                                                            </div>
                                                            <div className="bxa-form-group">
                                                                <label htmlFor="bxa-delivery-name">Email Address</label>
                                                                <input 
                                                                    type="email" 
                                                                    id="billing-email" 
                                                                    className="bxa-form-control" 
                                                                    autoComplete="email" 
                                                                    name="billing-email" 
                                                                    required 
                                                                    placeholder="john@example.com"
                                                                    value={this.state.email}
                                                                    onChange={(e) => this.setState({email: e.target.value})}
                                                                />
                                                            </div>
                                                            <div className="bxa-form-group" id="card-element"></div>
                                                            <div className="bxa-form-group" id="card-errors">{payError}</div>
                                                            <button className="bxa-btn bxa-btn-light bxa-btn-block" type="button" id="close-bxa-two">Cancel</button>
                                                            <button 
                                                                id="bxa-pay-btn" 
                                                                className="bxa-btn bxa-btn-primary bxa-btn-block" 
                                                                type="button"
                                                                onClick={this.makePayment}
                                                            >Pay & Complete Order</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bxa-tab-pane bxa-fade" id="bxa-complete" role="tabpanel" aria-labelledby="third-tab"> 
                                                <div className="bxa-row" id="bxa-lottie"></div>
                                                <div className="bxa-row">
                                                    <div className="bxa-col-lg-12">
                                                        <div className="bxa-rounded bxa-p-4 bxa-mb-4 bxa-text-center">
                                                            <h3 className="bxa-text-5 bxa-mb-4 bxa-headers">Thank you for your order.</h3>
                                                            <p className="bxa-text-4 bxa-mb-4 bxa-headers">You will receive email confirmation shortly.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bxa-tab-pane bxa-fade" id="bxa-error" role="tabpanel" aria-labelledby="fourth-tab"> 
                                                <div className="bxa-row" id="bxa-lottie-error"></div>
                                                <div className="bxa-row">
                                                    <div className="bxa-col-lg-12">
                                                        <div className="bxa-rounded bxa-p-4 bxa-mb-4 bxa-text-center">
                                                            <h3 className="bxa-text-5 bxa-mb-4 bxa-headers" id="bxa-error-msg"></h3>
                                                            <p className="bxa-text-4 bxa-mb-4 bxa-headers">Refresh this window to start this checkout again.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!this.state.error && this.state.loading && 
            <Loading/>
            }
        </DocumentMeta>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    actions
)(Checkout);