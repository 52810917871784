import React from 'react';

export default class Error extends React.Component {
    render() {
        return (
            <div style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                backgroundColor: '#f0f2f5'
            }}>
                <div style={{
                    paddingTop: '50px'
                }}>
                    <h2>Oops...</h2>
                    <p style={{
                        fontSize: "20px", 
                        marginTop: "0px",
                        marginLeft: '20px'
                    }}>Unable to locate a store or product at this location.</p>
                    <img 
                        src="/logo.png"
                        style={{
                            position: 'fixed',
                            width: "100px",
                            bottom: "40px",
                            marginLeft: "-50px"
                        }}
                    />
                </div>
            </div>
        )
    }
}