import React from 'react';
import LoadingBar from 'react-top-loading-bar';

export default class Loading extends React.Component {
    
    state = {
        loadingBarProgress: 10
    }

    componentDidMount() {
        let perc = 0, time = 0;
        let interval = setInterval(() => {
            perc = perc + 5;
            time = time + 100;
            this.setState({loadingBarProgress: perc});
            if (time >= 2500) {
                clearInterval(interval);
            }
        }, 100);
        
    }

    render() {
        return (
            <div style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                backgroundColor: '#f0f2f5'
            }}>
                <div style={{

                }}>
                    <LoadingBar
                        progress={this.state.loadingBarProgress}
                        height={3}
                        color='#2c7be5'
                        onRef={ref => (this.LoadingBar = ref)}
                    />
                    <img 
                        src="/loading.gif"
                        style={{
                            height: "250px",
                            marginTop: "100px"
                        }}
                    />
                    <p style={{
                        fontWeight: 'bold', 
                        fontSize: "20px", 
                        marginTop: "0px",
                        marginLeft: '20px'
                    }}>{`   `}loading checkout...</p>
                    <img 
                        src="/logo.png"
                        style={{
                            position: 'fixed',
                            width: "100px",
                            bottom: "40px",
                            marginLeft: "-50px"
                        }}
                    />
                </div>
            </div>
        )
    }
}